const OfficerAboutData = {
    "Thomas_Chu": {
        "bio": "", 
        "name": "Thomas Chu", 
        "src": "HKN_generic.jpg", 
        "id": 0, 
        "fun": "", 
        "position": "President", 
        "email": ""
    }, 
    "Rundong_Jiang": {
        "bio": "", 
        "name": "Rundong (Gabriel) Jiang", 
        "src": "HKN_generic.jpg", 
        "id": 1, 
        "fun": "", 
        "position": "Internal Vice President", 
        "email": ""
    }, 
    "Simba_Chen": {
        "bio": "Hi! I'm Simba and I am a 3rd year electrical engineering major with a minor in Japanese. I like karaoke, basketball, and BCD tofu house!", 
        "name": "Simba Chen", 
        "src": "Simba_Chen.png", 
        "id": 2, 
        "fun": "I was in fact named after the Lion King.", 
        "position": "External Vice President", 
        "email": ""
    }, 
    "Nathan_Chen": {
        "bio": "", 
        "name": "Nathan Chen", 
        "src": "HKN_generic.jpg", 
        "id": 3, 
        "fun": "", 
        "position": "External Vice President", 
        "email": ""
    }, 
    "Rachel_Yen": {
        "bio": "Hello everyone! My name is Rachel Yen and I'm a third year electrical engineering major. I'm a huge Switfie and love all my sweet treats after meals. I also love taking pictures and finding new places to go on walks!", 
        "name": "Rachel Yen", 
        "src": "Rachel_Yen.jpg", 
        "id": 4, 
        "fun": "I have a streak on BeReal of over 800 days :p", 
        "position": "Treasurer", 
        "email": ""
    }, 
    "Howard_Shu": {
        "bio": "I'm a second year electrical engineering major particularly interested in signal processing and IC design. In my free time, I enjoy working out and playing basketball and golf.", 
        "name": "Howard Shu", 
        "src": "Howard_Shu.jpg", 
        "id": 5, 
        "fun": "I passed my driving test on 2/22/22 on my 2nd attempt", 
        "position": "Secretary", 
        "email": ""
    }, 
    "Nathan_Leobandung": {
        "bio": "", 
        "name": "Nathan Leobandung", 
        "src": "HKN_generic.jpg", 
        "id": 6, 
        "fun": "", 
        "position": "Webmaster", 
        "email": ""
    }, 
    "Georgios_Spanodimos": {
        "bio": "Junior EE.", 
        "name": "Georgios Spanodimos", 
        "src": "Georgios_Spanodimos.jpg", 
        "id": 7, 
        "fun": "I hate EE", 
        "position": "Webmaster", 
        "email": ""
    }, 
    "Samantha_Redifer": {
        "bio": "Hi! I'm Samantha, and I'm a second year majoring in Computer Engineering. I love traveling, going to cute cafes, eating good food, being bad at video games, and going to concerts!", 
        "name": "Samantha Redifer", 
        "src": "Samantha_Redifer.jpg", 
        "id": 8, 
        "fun": "I spend all my money on Hirono figures and smiskis :D", 
        "position": "Publicity", 
        "email": ""
    }, 
    "Patrick_Mulligan": {
        "bio": "", 
        "name": "Patrick Mulligan", 
        "src": "HKN_generic.jpg", 
        "id": 9, 
        "fun": "", 
        "position": "Historian", 
        "email": ""
    }, 
    "Edward_Liu": {
        "bio": "Hi, my name's Edward and I'm currently a 3rd year Electrical Engineering major. I was born and raised in San Diego and enjoy watching all sports, especially football and basketball. I also love hiking and running or really any active activity.", 
        "name": "Edward Liu", 
        "src": "Edward_Liu.jpg", 
        "id": 10, 
        "fun": "I got my drivers license on 2/22/22", 
        "position": "Social", 
        "email": ""
    }, 
    "Harry_Wang": {
        "bio": "I am a 2nd year Computer Engineering major from Irvine, CA. Computers funny.", 
        "name": "Harry Wang", 
        "src": "Harry_Wang.jpg", 
        "id": 11, 
        "fun": "This statement is false.", 
        "position": "Social", 
        "email": ""
    }, 
    "Samuel_Chua": {
        "bio": "Hi! I'm Samuel, a Computer Science & Engineering Junior. I come from Singapore and I'm passionate about R&D in fields such as Robotics and AI.", 
        "name": "Samuel Chua", 
        "src": "Samuel_Chua.jpg", 
        "id": 12, 
        "fun": "I was a Lieutenant and served in the Air Force", 
        "position": "Mentorship", 
        "email": ""
    }, 
    "Rohan_Sinha": {
        "bio": "", 
        "name": "Rohan Sinha", 
        "src": "HKN_generic.jpg", 
        "id": 13, 
        "fun": "", 
        "position": "Mentorship", 
        "email": ""
    }, 
    "Ethan_Lai": {
        "bio": "", 
        "name": "Ethan Lai", 
        "src": "HKN_generic.jpg", 
        "id": 14, 
        "fun": "", 
        "position": "Tutoring", 
        "email": ""
    }, 
    "Darren_Chin": {
        "bio": "Darren is a 3rd year computer engineering major. On campus, he is involved with robotics and control research at the Structures Computer Interaction Lab and with InterVarsity Bruin Christian Fellowship. In his free time, Darren enjoys reading, running, and spending time outside.", 
        "name": "Darren Chin", 
        "src": "Darren_Chin.jpg", 
        "id": 15, 
        "fun": "I like durians.", 
        "position": "Workshops", 
        "email": ""
    }, 
    "Mingxin_Marshall_Liu": {
        "bio": "I'm Marshall Liu, a junior student majoring in Computer Engineering. I LOVE good Chinese food and my favorite cuisine is Malatang!", 
        "name": "Mingxin Marshall Liu", 
        "src": "Mingxin_Liu.png", 
        "id": 16, 
        "fun": "I speak Cantonese!", 
        "position": "Workshops", 
        "email": ""
    }, 
    "Lydia_Huang": {
        "bio": "", 
        "name": "Lydia Huang", 
        "src": "HKN_generic.jpg", 
        "id": 17, 
        "fun": "", 
        "position": "Membership", 
        "email": ""
    }, 
    "Henry_Shu": {
        "bio": "", 
        "name": "Henry Shu", 
        "src": "HKN_generic.jpg", 
        "id": 18, 
        "fun": "", 
        "position": "Faculty/Alumni", 
        "email": ""
    }, 
    "Sofia_Behzadi": {
        "bio": "", 
        "name": "Sofia Behzadi", 
        "src": "HKN_generic.jpg", 
        "id": 19, 
        "fun": "", 
        "position": "Faculty/Alumni", 
        "email": ""
    }, 
    "Charles_Zhang": {
        "bio": "", 
        "name": "Charles Zhang", 
        "src": "HKN_generic.jpg", 
        "id": 20, 
        "fun": "", 
        "position": "Faculty/Alumni", 
        "email": ""
    }, 
    "Iris_Shen": {
        "bio": "", 
        "name": "Iris Shen", 
        "src": "HKN_generic.jpg", 
        "id": 21, 
        "fun": "", 
        "position": "Outreach", 
        "email": ""
    }, 
    "Jaz_Reyes": {
        "bio": "", 
        "name": "Jaz Reyes", 
        "src": "HKN_generic.jpg", 
        "id": 22, 
        "fun": "", 
        "position": "Outreach", 
        "email": ""
    }
};
export default OfficerAboutData;
